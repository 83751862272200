var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-layout",
    {
      staticClass: "take-photo-mission",
      attrs: { column: "", "fill-height": "" },
    },
    [
      _vm.isViewerAuditorLike
        ? _c("ResizableText", {
            staticClass: "h-full w-full",
            attrs: { message: "The users are taking pictures" },
          })
        : _vm._e(),
      _vm.isScribe && !_vm.isHost && _vm.inPlayMode
        ? _c(
            "v-flex",
            [
              _c("PictureCapture", {
                staticClass: "fill-height",
                on: { sendFile: _vm.onSendFile },
              }),
            ],
            1
          )
        : [
            _vm.mode === _vm.Mode.Explain
              ? _c(
                  "v-layout",
                  {
                    staticClass: "mission-instructions",
                    attrs: { "d-flex": "", column: "" },
                  },
                  [
                    _c(
                      "v-flex",
                      { attrs: { grow: "", "d-flex": "" } },
                      [
                        _c("ResizableText", {
                          attrs: { message: _vm.instructions },
                        }),
                      ],
                      1
                    ),
                    _vm.game.importedFromBreadcrumb && _vm.presentationMission
                      ? _c("v-flex", {
                          staticClass:
                            "mission-instructions__presentation-image",
                          style:
                            "background: url('" +
                            _vm.presentationMission +
                            "') center / contain no-repeat;",
                        })
                      : _vm._e(),
                  ],
                  1
                )
              : _c("v-flex", { attrs: { "d-flex": "", "align-center": "" } }, [
                  _c(
                    "button",
                    { staticClass: "start-video-button not-scribe" },
                    [_vm._v(" Only Scribes Take Photo ")]
                  ),
                ]),
          ],
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }