<template>
  <v-layout column fill-height class="take-photo-mission">
    <!-- Mission question -->
    <ResizableText
      v-if="isViewerAuditorLike"
      message="The users are taking pictures"
      class="h-full w-full"
    />
    <v-flex v-if="isScribe && !isHost && inPlayMode">
      <PictureCapture class="fill-height" @sendFile="onSendFile" />
    </v-flex>
    <template v-else>
      <v-layout
        d-flex
        column
        class="mission-instructions"
        v-if="mode === Mode.Explain"
      >
        <v-flex grow d-flex>
          <ResizableText :message="instructions" />
        </v-flex>
        <v-flex
          v-if="game.importedFromBreadcrumb && presentationMission"
          class="mission-instructions__presentation-image"
          :style="`background: url('${presentationMission}') center / contain no-repeat;`"
        >
        </v-flex>
      </v-layout>
      <v-flex v-else d-flex align-center>
        <button class="start-video-button not-scribe">
          Only Scribes Take Photo
        </button>
      </v-flex>
    </template>
  </v-layout>
</template>

<script>
import { mapGetters } from "vuex"

import ResizableText from "@/components/GroupTeams/Common/Games/ResizableText.vue"
import uniqid from "uniqid"
import { GameMixin } from "@/mixins"
import { uploadMedia } from "@/services/storage.service"
import { UserRole } from "@/types/user"
import User from "@shared/User"
import Mode from "@shared/enums/Mode"

export default {
  name: "TakePhoto",
  mixins: [GameMixin],
  props: {
    mission: Object,
    customUser: {
      required: false,
      type: Object
    }
  },
  data() {
    return {
      Mode
    }
  },
  components: {
    ResizableText,
    PictureCapture: () => import("@/components/PictureCapture.vue")
  },
  computed: {
    ...mapGetters({ mode: "getCurrentMode", teams: "chats" }),
    isViewerAuditorLike() {
      return this.$store.getters["group/isViewerAuditorLike"]
    },
    game() {
      return this.$store.getters.game
    },
    presentationMission() {
      return this.mission?.presentationImage
    },
    instructions() {
      return this.mission?.instructions
    },
    isScribe() {
      return User.isScribe(this.user)
    },
    isHost() {
      return [UserRole.Host, UserRole.Audit].includes(this.user.role)
    },
    user() {
      return this.customUser ?? this.$store.getters.user
    },
    token() {
      return this.$store.state.auth.token
    },
    currentMissionID() {
      return this.mission?.id
    },
    inPlayMode() {
      return [Mode.Play, Mode.Huddle].includes(this.mode)
    }
  },
  methods: {
    async onSendFile(file) {
      const fileName = `gamephotos/${uniqid()}-${this.user.username}.jpg`
      try {
        this.imageUrl = await uploadMedia({
          fileName,
          blob: file,
          token: this.token
        })
      } catch (e) {
        alert(
          "It looks like you are behind a firewall or VPN and therefore can't save your photo."
        )
        this.imageUrl = "https://jaznau.com/wp-content/uploads/2019/08/vpn.jpg"
      }

      this.hasImage = true
      this.missionStatus = "completed"

      await this.checkAnswer({
        sentMissionID: this.currentMissionID
      })
    }
  }
}
</script>

<style lang="scss">
.take-photo-mission {
  .mission-instructions {
    padding: 25px 10px 5px;
    color: #fff;
  }

  .start-video-button:hover {
    background-color: $color-brown;
    color: $color-white;
    outline: none;
  }

  .start-video-button {
    background-color: $primary_accent_color !important;
    color: $color-beige-light;
    border-radius: 6px;
    padding: 8px 18px;
    font-size: 22px;
    font-weight: bolder;
    outline: none;
  }

  .not-scribe {
    background-color: $color-grey-light3 !important;
    cursor: none;
  }

  .mission-instructions {
    &__presentation-image {
      height: 30%;
    }
  }
}
</style>
